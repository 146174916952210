import React, { useState } from "react";
import { Seo } from "../components/seo";
import Layout from "../hoc/Layout/Layout";
import Instructions from "../components/Instructions/Instructions";
import BanerSection from "../components/BanerSection/BanerSection";
import AdvantagesSection from "../components/AdvantagesSection/AdvantagesSection";
import Hero from "../components/Hero/Hero";
import Cta from "../components/Cta/Cta";

// markup
const IndexPage = () => {
  // eslint-disable-next-line
  const [bookingActive, setBookingActive] = useState(false);
  // eslint-disable-next-line
  const [serviceChosen, setServiceChosen] = useState(false);
  // eslint-disable-next-line
  const [ableToGoBack, setAbleToGoBack] = useState(false);
  const [finishBookingStep, setFinishBookingStep] = useState();

  // useEffect(() => {
  // 	const city = localStorage.getItem('city');
  // 	if (city === 'linkoping') {
  // 		navigate('/linkoping/')
  // 	}
  // }, []);

  return (
    <Layout
      bookingActive={bookingActive}
      serviceChosen={serviceChosen}
      ableToGoBack={ableToGoBack}
      finishBookingStep={finishBookingStep}
      setFinishBookingStep={setFinishBookingStep}
    >
      <Seo title="Städfirma i Jönköping | Miljövänlig Städfirma - Städföretag Putso" />
      <Hero />
      <>
        <Instructions
          text1="Ange typ och antal fönster att beräkna ditt pris."
          text2="Välj det datum du önskar få fönsterputsningen. Vi återkopplar om datumet finns ledigt."
          text3="Vi kommer till dig och putsar. Säkert, smidigt, enkelt!"
        />
        <BanerSection
          title="Professionell fönsterputs"
          description="Låt våra duktiga fönsterputsare skapa en härligare känsla i ditt hem eller företag och njut av att se solen skina genom rutorna igen. Vi klarar alla typer av jobb inom fönsterputsning och ger oss inte förrän jobbet är korrekt utfört och du är nöjd med resultatet. "
        />
        <BanerSection
          reverseOrder
          title="Hög kvalitet"
          description="Vårt mål på Putso är att erbjuda dig som kund en hög kvalitativ fönsterputs och bästa tänkbara service, oavsett storlek på uppdrag. Vi erbjuder dig alltid kunnig och utbildad personal med hög servicekänsla och pålitlighet. Nöjda kunder är vår viktigaste fjäder i hatten!"
        />
        <AdvantagesSection
          type="Index"
          text1={{
            heading: "Mästare på fönsterputs",
            description:
              "Personal med utbildning i fönsterputs. Vi vet hur vi ger dig skinande rena fönster med minsta möjliga besvär, till ett riktigt bra pris.",
          }}
          text2={{
            heading: "Inga bindningstider",
            description:
              "Vår putsning har ingen bindningstid. Pausa ditt abonnemang när du vill, du är alltid välkommen tillbaka. Du ska inte behöva känna dig låst, skönt va?",
          }}
          text3={{
            heading: "Miljömärkta medel ingår",
            description:
              "Vi värdesätter miljön och agerar så ansvarsfullt som möjligt när det kommer till alla steg i vår putstjänst. Därför tar vi med oss miljövänliga medel när vi putsar - utan att kompromissa med kvaliteten.",
          }}
          text4={{
            heading: "Enkel onlinebokning",
            description:
              "Att skaffa putshjälp ska vara enkelt och smidigt. Boka engångsputs eller regelbunden fönsterputsning från Putso enkelt online. ",
          }}
        />
        <Cta />
      </>
    </Layout>
  );
};

export default IndexPage;
