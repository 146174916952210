import { Link } from "gatsby";
import React, { useState } from "react";
import { BsCheck } from "react-icons/bs";
import { MdClose, MdArrowBack } from "react-icons/md";
import FormInput from "../FormInput/FormInput";
import "./Hero.scss";
import { phoneBookingEmail } from "../../helpers/emails";
// import { isValidPhone } from "../../helpers/bookingUtils";

const Hero = () => {
  const [popupStep, setPopupStep] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleSubmit = () => {
    phoneBookingEmail(phoneNumber, () => setPopupStep(3));
  };

  return (
    <>
      <div className="hero">
        <div class="hero__content flex flex-col h-full justify-center max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          <h1 className="sm:text-5xl text-3xl text-center sm:text-left mb-6 font-bold">
            Klara, Färdiga, Putso!
          </h1>
          <p className="sm:text-lg text-center sm:text-left text-base mb-8 max-w-lg">
            Få skinande rena fönster av våra professionella fönsterputsare till
            ett fast pris och med nöjdhetsgaranti.
          </p>
          <div
            onClick={() => setPopupStep(1)}
            aria-hidden="true"
            className="hero-btn px-9 py-3 w-max mb-8 cursor-pointer border border-transparent text-base font-medium rounded-full text-white bg-indigo-600 transition-opacity hover:opacity-80"
          >
            Beställ fönsterputs
          </div>
          <div className="flex items-center justify-center sm:justify-start sm:mb-2 mb-4">
            <div className="flex items-center text-sm sm:text-base mr-4">
              <BsCheck />
              <p className="ml-1 check-p">Professionella fönsterputsare</p>
            </div>
            <div className="flex items-center text-sm sm:text-base mr-4">
              <BsCheck />
              <p className="ml-1 check-p">Alltid fast pris</p>
            </div>
          </div>
          <div className="flex items-center justify-center sm:justify-start mb-2">
            <div className="flex items-center text-sm sm:text-base mr-4">
              <BsCheck />
              <p className="ml-1 check-p">Nöjdhetsgaranti</p>
            </div>
            <div className="flex items-center text-sm sm:text-base mr-4">
              <BsCheck />
              <p className="ml-1 check-p">Medel och material ingår</p>
            </div>
          </div>
        </div>
      </div>
      {popupStep && (
        <div
          className="fixed w-full h-full z-50 bg-gray-800 bg-opacity-50 top-0 left-0 flex p-5 items-center justify-center"
          style={{ backdropFilter: "blur(6px)" }}
          onClick={() => setPopupStep(null)}
          aria-hidden="true"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            class="rounded-2xl bg-white max-w-3xl md:px-24 md:py-16 px-7 py-12 relative"
            aria-hidden="true"
          >
            {popupStep === 2 && (
              <div
                className="close absolute md:left-6 md:top-6 left-4 top-4 cursor-pointer"
                aria-hidden="true"
                onClick={() => setPopupStep((p) => p - 1)}
              >
                <MdArrowBack size={24} />
              </div>
            )}
            <div
              onClick={() => setPopupStep(null)}
              aria-hidden="true"
              className="close absolute md:right-6 md:top-6 right-4 top-4 cursor-pointer"
            >
              <MdClose size={24} />
            </div>
            {popupStep === 1 && (
              <>
                <h1 className="text-main-600 text-3xl mb-4 text-center font-bold uppercase">
                  Nu är det dags för rena fönster!
                </h1>
                <p className="text-gray-900 mb-9 text-center text-lg">
                  Du kan välja att själv boka fönsterputsning enkelt direkt
                  online eller så ringer vi upp dig och hjälper till med
                  bokningen.
                </p>
                <div className="flex md:items-start items-center justify-around md:flex-row flex-col">
                  <Link
                    to="/booking"
                    className="px-9 py-3 mb-3 md:mb-0 border block md:w-max w-full text-center border-transparent text-base font-medium rounded-full text-white bg-indigo-600 transition-opacity hover:opacity-80"
                  >
                    Boka direkt online{" "}
                    <span role="img" aria-label="icon">
                      ⚡
                    </span>
                  </Link>
                  <div
                    aria-hidden="true"
                    onClick={() => setPopupStep(2)}
                    className="px-9 py-3 border cursor-pointer text-base md:w-max w-full text-center font-medium rounded-full text-main-600 bg-white transition-opacity hover:opacity-80"
                    style={{ borderColor: "#011e41" }}
                  >
                    Ring mig, vill veta mer först{" "}
                    <span role="img" aria-label="icon">
                      📞
                    </span>
                  </div>
                </div>
              </>
            )}

            {popupStep === 2 && (
              <>
                <h1 className="text-main-600 text-3xl mb-3.5 text-center font-bold">
                  UTMÄRKT VAL!{" "}
                  <span role="img" aria-label="icon">
                    😎
                  </span>
                </h1>
                <p className="text-gray-900 mb-9 text-center text-lg">
                  Lämna ditt nummer så ringer vår representant upp dig inom kort
                  med mer information samt svarar på alla dina frågor.
                </p>
                <div className="flex sm:items-start items-center justify-center sm:flex-row flex-col">
                  <div className="sm:w-8/12 w-full mr-2 sm:mb-0 mb-5">
                    <FormInput
                      value={phoneNumber}
                      onChange={(val) => setPhoneNumber(val)}
                      placeholder="Telefonnummer"
                      type="tel"
                    />
                  </div>
                  <button
                    disabled={phoneNumber.length < 5}
                    onClick={handleSubmit}
                    className="phone-book-btn px-9 py-3 border border-transparent text-base font-medium rounded-full text-white bg-indigo-600 transition-opacity hover:opacity-80"
                  >
                    Ring mig
                  </button>
                </div>
              </>
            )}
            {popupStep === 3 && (
              <>
                <div className="text-5xl text-center mb-6">
                  <span role="img" aria-label="icon">
                    ✅
                  </span>
                </div>
                <h1 className="text-main-600 text-3xl mb-3 text-center font-bold">
                  TACK! VI KONTAKTAR DIG VÄLDIGT SNART!
                </h1>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Hero;
