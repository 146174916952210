import { Link } from "gatsby";
import React from "react";
import "./Instructions.scss";

const Instructions = ({
  text1,
  text2,
  text3,
  heading1,
  heading2,
  heading3,
  bg,
  linkopingHeader,
  linkopingDescription,
}) => {
  return (
    <>
      <div
        class="relative py-16 sm:py-24 lg:py-24"
        style={{ background: bg ? "#fafafa" : "#fff" }}
      >
        <div class="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-6 lg:max-w-7xl">
          <p class="mt-2 text-3xl font-extrabold text-main-600 tracking-tight sm:text-4xl">
            Putsning av fönster i 3 enkla steg
          </p>
          <p class="mt-5 max-w-prose mx-auto text-xl text-main-600">
            Det ska inte vara krångligt, vi ser till att du kommer igång så
            snabbt som möjligt.
          </p>
          <div class="mt-12">
            <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
              <div class="pt-6">
                <div class="flow-root  rounded-lg px-6 pb-8">
                  <div class="-mt-6">
                    <div>
                      <span class="inline-flex items-center justify-center p-6 bg-white rounded-full shadow-lg">
                        <p class="step-icon h-6 w-6 font-bold text-lg text-main-600">
                          1
                        </p>
                      </span>
                    </div>
                    <p class="mt-5 text-base text-main-600">{text1}</p>
                  </div>
                </div>
              </div>

              <div class="pt-6">
                <div class="flow-root  rounded-lg px-6 pb-8">
                  <div class="-mt-6">
                    <div>
                      <span class="inline-flex items-center justify-center p-6 bg-white rounded-full shadow-lg">
                        <p class="step-icon h-6 w-6 font-bold text-lg text-main-600">
                          2
                        </p>
                      </span>
                    </div>
                    <p class="mt-5 text-base text-main-600">{text2}</p>
                  </div>
                </div>
              </div>

              <div class="pt-6">
                <div class="flow-root  rounded-lg px-6 pb-8">
                  <div class="-mt-6">
                    <div>
                      <span class="inline-flex items-center justify-center p-6 bg-white rounded-full shadow-lg">
                        <p class="step-icon h-6 w-6 font-bold text-lg text-main-600">
                          3
                        </p>
                      </span>
                    </div>
                    <p class="mt-5 text-base text-main-600">{text3}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-center">
          <div class="mt-8 flex lg:mt-4 lg:flex-shrink-0 ">
            <div class="inline-flex rounded-full shadow">
              <Link
                to="/booking"
                class="inline-flex items-center justify-center px-8 py-4 border border-transparent text-base font-medium rounded-full text-white bg-indigo-600 transition-opacity hover:opacity-70"
              >
                Beräkna ditt pris
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Instructions;
